<script setup>
	import { useUserStore } from '~/stores/user.store';
	import { computed } from 'vue';

	const { t } = useI18n()
	const userStore = useUserStore();
	const config = useRuntimeConfig();

	const accountUrlComputed = computed(() => config.public.accountUrl);
	const favoriteUrlComputed = computed(() => new URL('favorites/listings', config.public.accountUrl).href);

	await userStore.getUserData();
	const user = computed(() => userStore.userGetter());
	const logout = () => userStore.logout();
	const items = [
		{
			label: t('client.my_account'), 
			url: accountUrlComputed.value,
		},{
			label: t('client.favorites'),
			url: new URL('favorites/listings', accountUrlComputed.value).href,
		},{
			label: t('client.logout'),
			slot: 'logout'
		}
	]
</script>
	<template>
		<div v-if="user" class="dropdown currency me-2 cursor-pointer">
			<w-drop-down-with-click :marginTop="5">
				<template #default><a href="javascript:void(0)">{{ user.first_name + ' ' + user.last_name }}</a></template>
				<template #item>
					<div class="w-full flex flex-col text-left">
						<div v-for="item in items" :key="item.label" class="w-full h-10 py-1 border-t first:border-t-0">
							<a
								v-if="item.url"
								:href="item.url"
								class="block w-full pl-2 text-left h-full rounded-lg hover:bg-neutral-100"
							>
								{{ item.label }}
							</a>
							<button
								v-else
								@click="logout"
								class="w-full pl-2 text-left h-full rounded-lg hover:bg-neutral-100"
							>
								{{ item.label }}
							</button>
						</div>
					</div>
				</template>
			</w-drop-down-with-click>
		</div>
		<div v-else-if="accountUrlComputed">
			<a :href="accountUrlComputed" class="btn btn-link btn-sm dropdown-toggle" style="cursor: pointer">
					{{ $t('client.login_register') }}
			</a>
		</div>
	</template>

